import React from 'react';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import MoveUpIcon from '@mui/icons-material/MoveUp';

interface StageUpProps {
  onStageUp: () => void;
  onClose: () => void;
  idToStageUp?: string;
}

export default function StageUpDialog({ onStageUp, onClose, idToStageUp } : StageUpProps) {

  function handleStageUp() {
    onStageUp();
    idToStageUp = '';
  }

  function handleClose() {
    idToStageUp = '';
    onClose();
  }

  return (
        <Dialog open={!!idToStageUp} onClose={onClose} data-testid='stage-up-dialog'>
            <AppBar
                position='static'
            >
                <DialogTitle
                    sx={{
                      alignItems:'center',
                      display:'flex',
                      gap: '24px',
                    }}>
                        <Icon>
                            <MoveUpIcon fill="white"/>
                        </Icon>
                        <Typography style={{ marginRight: '20px' }}>
                            Stage Up
                        </Typography>
                </DialogTitle>
            </AppBar>
            <DialogContent>
                <Typography>
                    You are about to stage up the license content package
                </Typography>
                {idToStageUp &&
                    <Typography py='2rem' align='center' sx={{ fontWeight: 'bold' }}>
                        {idToStageUp}
                    </Typography>
                }
                <Typography>
                    Are you sure you want to proceed?
                </Typography>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem', justifyContent: 'space-between' }}>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={handleStageUp} variant="contained">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
  );
}