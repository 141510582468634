import { MRT_TableInstance } from 'material-react-table';

import {
  Box,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import Menu from '@mui/material/Menu';

import PushPinOffOutlined from './PushPinOffOutlined';
import PinningMenuItem from './PinningMenuItem';
import LicenseTranslationBundle from '../../../model/LicenseTranslationBundle';

interface Props {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  table: MRT_TableInstance<LicenseTranslationBundle>;
}

export default function PinningMenu({ anchorEl, setAnchorEl, table }: Props) {

  return (
        <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
        >
            <Box
                sx={{
                  p: '0.5rem',
                  pt: 0,
                }}
            >
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                  gap: '8px',
                  py: '4px',
                }}>
                    <Box px={'53px'}>
                        <IconButton onClick={() => table.resetColumnPinning(false)}>
                            <PushPinOffOutlined fill='error'/>
                        </IconButton>
                    </Box>
                    <Typography>
                        Unpin all
                    </Typography>
                </Box>
                <Divider />
                {table.getAllColumns()
                  .filter((c) => c.id !== 'actions')
                  .map((column) =>
                        <PinningMenuItem column={column} key={column.id}/>,
                  )
                }
            </Box>
        </Menu>
  );
}
