import { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MRT_ColumnDef } from 'material-react-table';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from '@mui/material/Icon';
import PostAddOutlined from '@mui/icons-material/PostAddOutlined';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { isLanguageColumn } from '../Dashboard';
import { MessageContext } from '../../context/MessageContext';
import LicenseTranslationBundle from '../../../model/LicenseTranslationBundle';
import { Brand } from '../../../model/Brand';

interface CreateRowProps {
  columns: MRT_ColumnDef<LicenseTranslationBundle>[];
  onClose: () => void;
  onSubmit: (createObject: LicenseTranslationBundle) => void;
  open: boolean;
}

export default function CreateRowDialog({ columns, onClose, onSubmit, open } : CreateRowProps) {

  const [createObject, setCreateObject] = useState<LicenseTranslationBundle>(
    getResetCreateObject(),
  );
  const messageContext = useContext(MessageContext);
  const [searchParams] = useSearchParams();

  function handleSubmit() {
    if (getHelperText()) {
      messageContext.addError('Invalid Input.');
      return;
    }
    const brand = searchParams.get('brand') as Brand;
    const submitObject = {
      ...createObject,
      oem: brand,
      lastUpdated: '',
    };
    onSubmit(submitObject);
    onClose();
    setCreateObject(getResetCreateObject());
  }

  function getResetCreateObject() {
    return {
      id: '',
      comment: '',
      translations: {},
    } as LicenseTranslationBundle;
  }

  function getHelperText() {
    if (!createObject.id) {
      return 'Must not be empty.';
    }
    return '';
  }

  return (
        <Dialog open={open} onClose={onClose} data-testid='create-entry-dialog'>
            <AppBar
                position='static'
            >
                <DialogTitle
                    sx={{
                      alignItems:'center',
                      display:'flex',
                      gap: '24px',
                    }}
                >
                    <Icon>
                        <PostAddOutlined sx={{ marginRight: '20px' }}/>
                    </Icon>
                    <Typography sx={{ marginRight: '20px' }}>
                        Create Entry
                    </Typography>
                </DialogTitle>
            </AppBar>
            <DialogContent>
                <Stack pt={2}
                    sx={{
                      width: '100%',
                      minWidth: { xs: '300px', sm: '360px', md: '400px' },
                      gap: '1.5rem',
                    }}
                >
                    <TextField
                        label='ID'
                        name='id'
                        error={!!getHelperText()}
                        helperText={getHelperText()}
                        onChange={(e) =>
                          setCreateObject({ ...createObject, id: e.target.value })
                        }
                    />
                    <TextField
                        label='Comment'
                        name='comment'
                        onChange={(e) =>
                          setCreateObject({ ...createObject, comment: e.target.value })
                        }
                    />
                    {columns
                      .filter((column) => isLanguageColumn(column.id as string))
                      .map((column) => (
                            <TextField
                                key={column.header.toLowerCase()}
                                label={column.header}
                                name={column.header.toLowerCase()}
                                onChange={(e) => {
                                  createObject.translations = { ...createObject.translations, [e.target.name]: e.target.value };
                                  setCreateObject(createObject);
                                }}
                            />
                      ))
                    }
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem', justifyContent: 'space-between' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit} variant="contained" disabled={!!getHelperText()}>
                    Create New Entry
                </Button>
            </DialogActions>
        </Dialog>
  );
}