import { Outlet } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { indigo, red } from '@mui/material/colors';

import AuthProvider from './context/AuthContext';
import DisplayMessage from './components/DisplayMessage';
import Header from './components/Header';
import MessageProvider from './context/MessageContext';

export const theme = createTheme({
  palette: {
    primary: {
      main: indigo[500],
    },
    secondary: {
      main: red[500],
    },
  },
});

export default function App() {

  return (
        <div style={{ width: '90%', margin: '0 auto' }}>
            <ThemeProvider theme={theme}>
                <MessageProvider>
                    <AuthProvider>
                        <Header/>
                        <Outlet/>
                        <DisplayMessage/>
                    </AuthProvider>
                </MessageProvider>
            </ThemeProvider>
        </div>
  );
}