import React from 'react';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';

interface DeleteRowProps {
  onDelete: () => void;
  onClose: () => void;
  idToDelete?: string;
}

export default function DeleteRowDialog({ onDelete, onClose, idToDelete } : DeleteRowProps) {

  function handleConfirmDelete() {
    onDelete();
    idToDelete = '';
  }

  function handleClose() {
    idToDelete = '';
    onClose();
  }

  return (
        <Dialog open={!!idToDelete} onClose={onClose} data-testid='delete-row-dialog'>
            <AppBar
                position='static'
                sx={{ background: '#d32f2f' }}
            >
                <DialogTitle
                    sx={{
                      alignItems:'center',
                      display:'flex',
                      gap: '24px',
                    }}>
                        <Icon>
                            <WarningIcon fill="white"/>
                        </Icon>
                        <Typography style={{ marginRight: '20px' }}>
                            Warning
                        </Typography>
                </DialogTitle>
            </AppBar>
            <DialogContent>
                <Typography>
                    You are about to delete:
                </Typography>
                {idToDelete &&
                    <Typography py='2rem' align='center' sx={{ fontWeight: 'bold' }}>
                        {idToDelete}
                    </Typography>
                }
                <Typography>
                    Are you sure you want to proceed?
                </Typography>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem', justifyContent: 'space-between' }}>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                <Button color="error" onClick={handleConfirmDelete} variant="contained">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
  );
}