import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './view/App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Dashboard from './view/pages/Dashboard';
import About from './view/pages/About';
import LogoutSuccess from './view/pages/LogoutSuccess';
import Callback from './view/pages/Callback';
import URL from './constants/url.json';
import ContentPackages from './view/pages/ContentPackages';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>,
    children: [
      {
        path: URL.ABOUT_PATH,
        element: <About/>,
      },
      {
        path: URL.DASHBOARD_PATH,
        element: <Dashboard/>,
      },
      {
        path: URL.CALLBACK_PATH,
        element: <Callback/>,
      },
      {
        path: URL.LOGOUT_SUCCESS_PATH,
        element: <LogoutSuccess/>,
      },
      {
        path: URL.CONTENT_PACKAGES_PATH,
        element: <ContentPackages/>,
      },
    ],
  },
]);

root.render(
  // <React.StrictMode>
  <RouterProvider router={router}/>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
