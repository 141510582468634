import React, { type MouseEvent, useState } from 'react';
import { MRT_TableInstance } from 'material-react-table';

import IconButton from '@mui/material/IconButton';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import Tooltip from '@mui/material/Tooltip';

import OptionsMenu from './OptionsMenu';
import LicenseTranslationBundle from '../../../model/LicenseTranslationBundle';

interface Props {
  table: MRT_TableInstance<LicenseTranslationBundle>;
  resetFunc: () => void;
}

export default function PinningMenuButton(props: Props) {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function handleClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  return (
        <React.Fragment>
            <Tooltip arrow title='Settings'>
                <IconButton
                    onClick={handleClick}
                >
                    <SettingsOutlined />
                </IconButton>
            </Tooltip>
            {anchorEl && (
                <OptionsMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    table={props.table}
                    resetFunc={props.resetFunc}
                />
            )}
        </React.Fragment>
  );
}