import {
  MRT_TableInstance,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
} from 'material-react-table';

import { Box } from '@mui/material';

import GlobalSearch from './GlobalSearch';
import OptionsMenuButton from './OptionsMenu/OptionsMenuButton';
import PinningMenuButton from './PinningMenu/PinningMenuButton';
import LicenseTranslationBundle from '../../model/LicenseTranslationBundle';

interface Props {
  table: MRT_TableInstance<LicenseTranslationBundle>;
  resetFunc: () => void;
}

export default function TopRightToolbar(props: Props) {
  return <Box
        sx={{
          display: 'flex',
        }}
    >
        <GlobalSearch table={props.table}/>
        <PinningMenuButton table={props.table}/>
        <MRT_ToggleDensePaddingButton table={props.table}/>
        <OptionsMenuButton table={props.table} resetFunc={props.resetFunc}/>
        <MRT_ToggleFullScreenButton table={props.table}/>
    </Box>;
}