import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';

import { AuthContext } from '../context/AuthContext';
import { MessageContext } from '../context/MessageContext';

export default function Callback() {

  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const [searchParams] = useSearchParams();

  const authCode = searchParams.get('code');

  if (authCode) {
    authContext.loadToken(authCode)
      .catch((error: Error) => messageContext.addError(error.message));
  }

  return (
        <Box display='flex' justifyContent='center' p='50px'>
            <Paper
                elevation={3}
                sx={{
                  width: '400px',
                  height: '200px',
                }}
            >
                <Grid
                    container
                    height='100%'
                    direction='column'
                    spacing={2}
                    justifyContent='center'
                    alignItems='center'
                >
                    <Grid item>
                        <CircularProgress/>
                    </Grid>
                    <Grid item>
                        <Typography>
                            Logging in ...
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
  );
}