import React, { useState } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  TextField,
  Typography,
} from '@mui/material';
import { FileUploadOutlined } from '@mui/icons-material';

interface ExportContentProps {
  onClose: () => void;
  onSubmit: (lang: string) => void;
  open: boolean;
}

export default function CreateContentPackageDialog({ onClose, onSubmit, open }: ExportContentProps) {
  const [id, setId] = useState('');

  function handleConfirmCreateContentPackage() {
    onSubmit(id);
    onClose();
    setId('');
  }

  function getHelperText() {
    if (!id) {
      return 'Must not be empty.';
    }
    return '';
  }

  return (
    <Dialog open={ open } onClose={ onClose } data-testid='export-content-dialog'>
      <AppBar
        position='static'
      >
        <DialogTitle
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: '24px',
          }}
        >
          <Icon>
            <FileUploadOutlined sx={{ marginRight: '20px' }}/>
          </Icon>
          <Typography sx={{ fontSize: 'large' }}>
            Create Content Package
          </Typography>
        </DialogTitle>
      </AppBar>
      <DialogContent>
        <TextField
          autoComplete="false"
          error={!!getHelperText()}
          label="Content Package Id"
          name="id"
          helperText={getHelperText()}
          onChange={ (e) =>
            setId(e.target.value)
          }
        />
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem', justifyContent: 'space-between' }}>
        <Button onClick={ onClose }>Cancel</Button>
        <Button onClick={ handleConfirmCreateContentPackage } variant='contained' disabled={!!getHelperText()} data-testid='export-content-dialog-button'>
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
}