import { MessageContextType } from '../view/context/MessageContext';

export async function handleResponse(messageContext: MessageContextType, response: Response, okMessage: string, nokMessage: string) {
  if (response.ok) {
    messageContext.addSuccess(okMessage);
  } else {
    messageContext.addError(nokMessage + ' Status: ' + response.status + ' ' +  (await response.json()).message);
  }
}

export function handleNetworkError(messageContext: MessageContextType, message: string) {
  messageContext.addError(message);
}