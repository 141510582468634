import React from 'react';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import { red } from '@mui/material/colors';

interface InstallProps {
  onInstall: () => void;
  onClose: () => void;
  idToInstall?: string;
}

export default function InstallDialog({ onInstall, onClose, idToInstall } : InstallProps) {

  function handleConfirmInstall() {
    onInstall();
    idToInstall = undefined;
  }

  function handleClose() {
    idToInstall = undefined;
    onClose();
  }

  return (
        <Dialog open={!!idToInstall} onClose={onClose} data-testid='install-dialog'>
            <AppBar
                position='static'
            >
                <DialogTitle
                    sx={{
                      alignItems:'center',
                      display:'flex',
                      gap: '24px',
                    }}>
                        <Icon>
                          <InstallDesktopIcon fill="white"/>
                        </Icon>
                        <Typography style={{ marginRight: '20px' }}>
                            Install License Content Package
                        </Typography>
                </DialogTitle>
            </AppBar>
            <DialogContent>
                <Typography>
                  You are about to install the license content package
                </Typography>
                {idToInstall &&
                    <Typography py='1.5rem' align='center' sx={{ fontWeight: 'bold' }}>
                        {idToInstall}
                    </Typography>
                }
                <Typography>
                  The existing data on this stage will be overwritten. <br/>
                  Changes that have not been packed into a content package will be lost.
                </Typography>
                <Typography pt='1.5rem' color={red[500]}>
                    Are you sure you want to proceed?
                </Typography>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem', justifyContent: 'space-between' }}>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={handleConfirmInstall} variant="contained">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
  );
}