import { useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import PostAddOutlined from '@mui/icons-material/PostAddOutlined';
import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined';
import Tooltip from '@mui/material/Tooltip';

import BrandSelect from './BrandSelect';
import { Divider } from '@mui/material';
import getHost from '../../Controller/GetHost';

export default function TopLeftToolbar(props: {
  onClickAddColumn: () => void,
  onClickAddRow: () => void,
  onClickExportContent: () => void,
}) {

  const [searchParams] = useSearchParams();
  const stage = getHost().stage;
  return <Box>
    <Grid
      container
      direction="row"
      spacing={ 1 }
    >
      <Grid item>
        <BrandSelect/>
      </Grid>
      <Grid item>
        <Tooltip arrow title="add language column">
                    <span>
                        <IconButton
                          onClick={ props.onClickAddColumn }
                          disabled={ !searchParams.get('brand') || stage === 'staging' || stage === 'prod' }
                          data-testid="add-language-button"
                        >
                            <PostAddOutlined sx={ { transform: 'rotate(-90deg) scaleY(-1)' } }/>
                        </IconButton>
                    </span>
        </Tooltip>
        <Tooltip arrow title="create entry">
                    <span>
                        <IconButton
                          onClick={ props.onClickAddRow }
                          disabled={ !searchParams.get('brand') || stage === 'staging' || stage === 'prod' }
                          data-testid="create-entry-button"
                        >
                            <PostAddOutlined/>
                        </IconButton>
                    </span>
        </Tooltip>
      </Grid>
      <Grid item>
        <Divider
          orientation="vertical"
        />
      </Grid>
      <Grid item>
        <Tooltip arrow title="create license content package">
                  <span>
                      <IconButton
                        onClick={ props.onClickExportContent }
                        disabled={ !searchParams.get('brand') || stage === 'staging' || stage === 'prod' }
                        data-testid="export-content-button"
                      >
                          <FileUploadOutlined/>
                      </IconButton>
                  </span>
        </Tooltip>
      </Grid>
    </Grid>
  </Box>;
}