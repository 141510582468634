import getHost from './GetHost';
import { Brand } from '../model/Brand';
import consts from '../constants/url.json';

export default class LicenseContentPackageController {

  async getAllContentPackagesForOem(oem: Brand, accessToken: string) {
    console.debug('fetching license content packages for oem: ' + oem);

    const url = new URL(getHost().url + consts.PACKAGE_PATH + '/' + oem.toLowerCase());

    return fetch(url.href, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth': accessToken,
      },
    });
  }

  async createContentPackage(oem: Brand, id: string, accessToken: string) {
    console.debug('export license content. id: ' + id);

    const url = new URL(getHost().url + consts.PACKAGE_PATH + '/' + oem.toLowerCase() + '/' + id);

    return fetch(url.href, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth': accessToken,
      },
    });
  }

  async stageUp(oem: Brand, id: string, accessToken: string) {
    console.debug('export license content. id: ' + id);

    const url = new URL(getHost().url + consts.PACKAGE_PATH + '/export/' + oem.toLowerCase() + '/' + id);

    return fetch(url.href, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth': accessToken,
      },
    });
  }

  install(oem: Brand, id: string, accessToken: string) {
    console.debug('install license content package. id: ' + id);

    const url = new URL(getHost().url + consts.PACKAGE_PATH + '/install/' + oem.toLowerCase() + '/' + id);

    return fetch(url.href, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth': accessToken,
      },
    });
  }


  delete(oem: Brand, id: string, accessToken: string) {
    console.debug('delete license content package. id: ' + id);

    const url = new URL(getHost().url + consts.PACKAGE_PATH + '/' + oem.toLowerCase() + '/' + id);

    return fetch(url.href, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-auth': accessToken,
      },
    });
  }
}