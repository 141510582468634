import { createBrowserHistory } from 'history';
import { jwtDecode } from 'jwt-decode';
import { createContext, useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation, useNavigate } from 'react-router-dom';

import { MessageContext } from './MessageContext';
import URL_CONSTS from '../../constants/url.json';
import MESSAGES from '../../constants/en.json';
import SRA_ROLES from '../../constants/roles.json';
import { CloudidpJwtAuthToken, TokenResponse, User } from '../../model/UserMeResponse';
import AuthTokenController from '../../Controller/AuthTokenController';
import { Brand } from '../../model/Brand';

const IDLE_LOGIN_MINUTES = 30;
const SECONDS_IN_MINUTE = 60;
const MILLIS_IN_SECOND = 1000;
const IDLE_LOGIN_MILLIS = IDLE_LOGIN_MINUTES * SECONDS_IN_MINUTE * MILLIS_IN_SECOND;
const IDLE_DEBOUNCE_MILLIS = 1000;
const REFRESH_BUFFER_PERCENT = 0.9;

export interface AuthContextType {
  loadToken: (authCode: string) => Promise<boolean>,
  getUser: () => User | undefined,
  isAuthenticated: () => boolean,
  isAuthorized: (oem: Brand) => boolean,
  logout: () => void,
  login: () => void,
}

export const AuthContext = createContext<AuthContextType>({
  getUser: () => undefined,
  isAuthenticated: () => false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isAuthorized: (oem: Brand) => false,
} as AuthContextType);

export default function AuthProvider({ children }: any) {

  const navigate = useNavigate();
  const location = useLocation();
  const browserHistory = createBrowserHistory();

  const messageContext = useContext(MessageContext);

  const [user, setUser] = useState<User>();
  const [refreshTimeout, setRefreshTimeout] = useState<NodeJS.Timeout>();

  const authTokenController = new AuthTokenController();

  const logoutTimer = useIdleTimer({
    timeout: IDLE_LOGIN_MILLIS,
    onIdle: logout,
    startOnMount: false,
    debounce: IDLE_DEBOUNCE_MILLIS,
  });

  useEffect(() => {

    loginRedirect();

  }, [location.pathname]);

  useEffect(() => {
    if (user?.decodedToken) {
      const expiresInMillis: number = user.expiresInSeconds * MILLIS_IN_SECOND;
      const refreshInMillis: number = expiresInMillis * REFRESH_BUFFER_PERCENT;
      setRefreshTimeout(setTimeout(refreshToken, refreshInMillis));
    }
  }, [user?.decodedToken]);

  function loginRedirect() {
    if (
      isAuthenticated() ||
      location.pathname.includes(URL_CONSTS.CALLBACK_PATH) ||
      location.pathname.includes(URL_CONSTS.LOGOUT_SUCCESS_PATH)
    ) {
      return;
    }

    const targetPathname = location.pathname;
    const targetSearch = location.search;
    if (targetPathname && !targetPathname.includes(URL_CONSTS.CALLBACK_PATH)) {
      localStorage.setItem('targetPath', targetPathname);
      if (targetSearch) {
        localStorage.setItem('targetSearch', targetSearch);
      }
    }

    const url = new URL(URL_CONSTS.AUTH_ENDPOINT);
    url.searchParams.append('client_id', URL_CONSTS.CIDP_CLIENT_ID);
    url.searchParams.append('redirect_uri', window.location.origin + '/' + URL_CONSTS.CALLBACK_PATH);
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('scope', 'openid profile');

    browserHistory.push(url.href);

  }

  function loadToken(authCode: string) : Promise<boolean> {
    return authTokenController.getAuthToken(authCode)
      .then(handleTokenResponse)
      .catch(handleTokenError);
  }

  function refreshToken() {
    setRefreshTimeout(undefined);

    if (user) {
      authTokenController.refreshAuthToken(user.refreshToken)
        .then(handleTokenResponse)
        .catch(handleTokenError);
    }
  }

  async function handleTokenResponse(response: Response) : Promise<boolean> {
    if (!response.ok) {
      messageContext.addError('Auth Error:' + response.body);
      return false;
    }

    const data: TokenResponse = await response.json();

    const newUser: User = {
      accessToken: data.access_token,
      decodedToken: jwtDecode(data.access_token),
      refreshToken: data.refresh_token,
      expiresInSeconds: data.expires_in,
    };
    setUser(newUser);

    if (getRoles(newUser.decodedToken).length === 0) {
      browserHistory.replace('/about');
      navigate('/about', { replace: true });
    } else {
      const target = {
        pathname: localStorage.getItem('targetPath') ?? '/',
        search: localStorage.getItem('targetSearch') ?? '',
      };
      localStorage.removeItem('targetPath');
      localStorage.removeItem('targetSearch');

      navigate(target, { replace: true });
    }

    logoutTimer.reset();

    return true;
  }

  function handleTokenError(e: Error) {
    messageContext.addError(MESSAGES.ERROR.GET_TOKEN + ' ' + e.message);

    browserHistory.replace('/');
    navigate('/');
    return false;
  }

  function getUser() : User | undefined {
    return user;
  }

  function isAuthenticated() : boolean {
    return !!user;
  }

  function isAuthorized(oem: Brand) : boolean {
    if (!isAuthenticated()) return false;

    const roles: string[] = getRoles(user!.decodedToken);

    const mappedRoles: string[] = roles
      .map((role) => getLscreeRoles().get(role) as string);

    return mappedRoles.includes(oem.toLowerCase());
  }

  function logout() : void {
    navigate(URL_CONSTS.LOGOUT_SUCCESS_PATH);

    setUser(undefined);
    clearTimeout(refreshTimeout);
    setRefreshTimeout(undefined);
  }

  async function login() {

    await navigate(URL_CONSTS.DASHBOARD_PATH);
  }

  return (
        <AuthContext.Provider value={{
          loadToken,
          getUser,
          isAuthenticated,
          isAuthorized,
          logout,
          login,
        }}>
            {children}
        </AuthContext.Provider>
  );
}

function getRoles(decodedToken: CloudidpJwtAuthToken) {
  const authorizedRoles: string[] | undefined = decodedToken.resource_access?.['vwag-kums'].roles;

  if (!authorizedRoles) return [];

  return authorizedRoles.filter((role: string) => getLscreeRoles().has(role));
}

function getLscreeRoles(): Map<string, string> {
  return new Map(Object.entries(SRA_ROLES));
}
