import React, { useContext, useState } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { CheckCircle, Login } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import Logout from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { Box, MenuList } from '@mui/material';

import { iconButtonStyle } from './Header';
import { AuthContext } from '../context/AuthContext';
import { Brand } from '../../model/Brand';

interface AccountMenuProps {
  disabled: boolean
}

export default function AccountMenu({ disabled }: AccountMenuProps) {
  const authContext = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleLogin() {
    authContext.login();
    handleClose();
  }

  function handleLogout() {
    authContext.logout();
    handleClose();
  }

  function renderAuthorization(oem: string) {
    const style: React.CSSProperties = {
      fontSize: '10pt',
      color: 'grey',
    };
    return (
            <p>
                <span style={style}>
                {
                    authContext.isAuthorized(oem as Brand) ?
                        <CheckCircle sx={{ fontSize: 'inherit', color: 'green' }}/>
                      :
                        <CancelIcon sx={{ fontSize: 'inherit', color: 'red' }}/>
                }
                </span>
                <span style={style}>
                    {oem}
                </span>
            </p>
    );
  }


  return (
        <React.Fragment>
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    sx={iconButtonStyle}
                >
                    <AccountCircleIcon sx={{ fontSize: 'inherit' }}/>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuList>
                    <Box sx={{
                      px: '16px',
                      py: '6px',
                    }}>
                        <p>{authContext.getUser()?.decodedToken.name}</p>
                        {renderAuthorization('Audi')}
                        {renderAuthorization('Bentley')}
                        {renderAuthorization('Lamborghini')}
                    </Box>

                  {!disabled && <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                      Logout
                    </MenuItem>

                  }

                  {disabled && <MenuItem onClick={handleLogin}>
                    <ListItemIcon>
                      <Login fontSize="small" />
                    </ListItemIcon>
                    Login
                  </MenuItem>
                  }
                </MenuList>
            </Menu>
        </React.Fragment>
  );
}
