import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MRT_ColumnDef, MRT_ColumnOrderState } from 'material-react-table';

import { Backdrop, Box, CircularProgress, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  BadgeOutlined,
  CommentOutlined,
  FormatQuote,
  Update,
} from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import CreateRowDialog from './dialogs/CreateRowDialog';
import DeleteRowDialog from './dialogs/DeleteRowDialog';
import AddLanguageDialog from './dialogs/AddLanguageDialog';
import CreateContentPackageDialog from './dialogs/CreateContentPackageDialog';
import '../App.css';
import { AuthContext } from '../context/AuthContext';
import { MessageContext } from '../context/MessageContext';
import PersistentMaterialReactTable from '../components/PersistentMaterialReactTable';
import CellEditMode from '../components/CellEditMode';
import LicenseTranslationBundleController from '../../Controller/LicenseTranslationBundleController';
import LanguageColumnController from '../../Controller/LanguageColumnController';
import { Brand } from '../../model/Brand';
import LicenseTranslationBundle from '../../model/LicenseTranslationBundle';
import Tooltip from '@mui/material/Tooltip';
import MESSAGES from '../../constants/en.json';
import LicenseContentPackageController from '../../Controller/LicenseContentPackageController';
import getHost from '../../Controller/GetHost';
import { handleNetworkError, handleResponse } from '../../utils/Utils';

export default function Dashboard() {

  const messageContext = useContext(MessageContext);
  const authContext = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const oem: Brand = searchParams.get('brand') as Brand;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rowData, setRowData] = useState<LicenseTranslationBundle[]>([]);
  const [columns, setColumns] = useState<MRT_ColumnDef<LicenseTranslationBundle>[]>([]);
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([]);

  const [isCreateRowDialogOpen, setIsCreateRowDialogOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState<string>();
  const [isAddLanguageDialogOpen, setIsAddLanguageDialogOpen] = useState(false);
  const [isExportContentDialogOpen, setIsExportContentDialogOpen] = useState(false);

  const bundleController = new LicenseTranslationBundleController();
  const languageColumnController = new LanguageColumnController();
  const contentHistoryController = new LicenseContentPackageController();

  useEffect(() => {
    if (oem) {
      loadColumnData().then((success) => {
        if (success) {
          loadRowData();
        }
      });
    }
  }, [oem]);


  function defineColumns(loadedLanguages: string[] | void) {
    if (!loadedLanguages || loadedLanguages.length === 0) {
      return;
    }

    let columnDefinitions = getColumnDefinitions(setIdToDelete, handleSaveCell, loadedLanguages);
    let storedColumnOrder = localStorage.getItem('mrt_columnOrder');
    setColumns(columnDefinitions);

    if (storedColumnOrder && storedColumnOrder.length > 0) {
      let parsed = JSON.parse(storedColumnOrder);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      loadedLanguages.every(language => parsed.includes(language))
        ? setColumnOrder(parsed)
        : setColumnOrder(columnDefinitions.map(col => col.id as string));
    } else {
      setColumnOrder(columnDefinitions.map(c => c.id as string));
    }
  }

  async function loadColumnData() {
    setIsLoading(true);
    console.debug('loading column definitions');
    let success: boolean = false;

    await languageColumnController.getAllLanguages(authContext.getUser()?.accessToken!)
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        let json : string[] = await response.json();
        if (json === null) {
          json = [];
        }
        const langs: string[] = json.map(lang => lang.toLowerCase());
        defineColumns(langs);
        success = true;
      })
      .catch((error) => {
        messageContext.addError(MESSAGES.ERROR.GET_LANGUAGES + ' ' + error.message);
        success = false;
      })
      .finally(() => setIsLoading(false));

    return success;
  }

  function loadRowData() {
    setIsLoading(true);
    console.debug('loading: ' + oem);

    bundleController.getBundlesByOem(oem, authContext.getUser()?.accessToken!)
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const json: LicenseTranslationBundle[] = await response.json();
        if (!json) {
          setRowData([]);
          messageContext.addWarning(MESSAGES.WARNING.NO_DATA);
          return;
        }

        setRowData(toLowerCaseTranslationsKeys(json));
      })
      .catch((error) => messageContext.addError(MESSAGES.ERROR.GET_ENTRIES + ' ' + error.message))
      .finally(() => setIsLoading(false));
  }

  function handleCreateRow(createObject: LicenseTranslationBundle) {
    setIsLoading(true);

    bundleController.createBundle(createObject, authContext.getUser()?.accessToken!)
      .then((response) => handleResponse(messageContext, response, MESSAGES.SUCCESS.CREATE_ENTRY, MESSAGES.ERROR.CREATE_ENTRY))
      .catch((error) => handleNetworkError(messageContext, MESSAGES.ERROR.CREATE_ENTRY + ' ' + error.message))
      .then(async () => loadRowData())
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleSaveCell(oldBundle: LicenseTranslationBundle, property: string, value: string) {
    setIsLoading(true);
    console.debug(`saving ${ property }: ${ value } for ${ oldBundle.id }`);

    let newBundle: LicenseTranslationBundle;
    if (isLanguageColumn(property)) {
      let newTranslations = { ...oldBundle.translations, [property]: value };
      newBundle = { ...oldBundle, translations: newTranslations };
    } else {
      newBundle = { ...oldBundle, [property]: value } as LicenseTranslationBundle;
    }

    bundleController.updateBundle(newBundle, authContext.getUser()?.accessToken!)
      .then((response) => handleResponse(messageContext, response, MESSAGES.SUCCESS.UPDATE_ENTRY + ' ' + oldBundle.id, MESSAGES.ERROR.UPDATE_ENTRY + ' ' + oldBundle.id))
      .catch((error) => handleNetworkError(messageContext, MESSAGES.ERROR.UPDATE_ENTRY + ' ' + oldBundle.id + ' ' + error.message))
      .then(async () => loadRowData())
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleDeleteRow() {
    setIsLoading(true);

    bundleController.deleteBundle(idToDelete!, oem, authContext.getUser()?.accessToken!)
      .then((response) => handleResponse(messageContext, response, MESSAGES.SUCCESS.DELETE_ENTRY + ' ' + idToDelete, MESSAGES.ERROR.DELETE_ENTRY + ' ' + idToDelete))
      .catch((error) => handleNetworkError(messageContext, MESSAGES.ERROR.DELETE_ENTRY + ' ' + idToDelete + ' ' + error.message))
      .then(async () => loadRowData())
      .finally(() => {
        setIsLoading(false);
        setIdToDelete(undefined);
      });
  }

  function handleAddLanguage(lang: string) {
    setIsLoading(true);

    languageColumnController.appendLanguage(lang, authContext.getUser()?.accessToken!)
      .then((response) => handleResponse(messageContext, response, MESSAGES.SUCCESS.UPDATE_LANGUAGES, MESSAGES.ERROR.UPDATE_LANGUAGES))
      .catch((error) => handleNetworkError(messageContext, MESSAGES.ERROR.UPDATE_LANGUAGES + ' ' + error.message))
      .then(async () => loadColumnData())
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleExportContent(contentPackageId: string) {
    setIsLoading(true);

    contentHistoryController.createContentPackage(oem, contentPackageId, authContext.getUser()?.accessToken!)
      .then((response) => handleResponse(messageContext, response, MESSAGES.SUCCESS.EXPORT_CONTENT, MESSAGES.ERROR.EXPORT_CONTENT))
      .catch((error) => handleNetworkError(messageContext, MESSAGES.ERROR.EXPORT_CONTENT + ' ' + error.message))
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Box mt='24px'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <PersistentMaterialReactTable
          columns={columns}
          data={rowData}
          setIsAddLanguageDialogOpen={setIsAddLanguageDialogOpen}
          setIsCreateRowDialogOpen={setIsCreateRowDialogOpen}
          setIsExportContentDialogOpen={setIsExportContentDialogOpen}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
        />
      </LocalizationProvider>
      <CreateRowDialog
        open={isCreateRowDialogOpen}
        columns={columns}
        onSubmit={handleCreateRow}
        onClose={() => setIsCreateRowDialogOpen(false)}
      />
      <DeleteRowDialog
        idToDelete={idToDelete}
        onDelete={handleDeleteRow}
        onClose={() => setIdToDelete(undefined)}
      />
      <AddLanguageDialog
        open={isAddLanguageDialogOpen}
        onSubmit={handleAddLanguage}
        onClose={() => setIsAddLanguageDialogOpen(false)}
      />
      <CreateContentPackageDialog
        open={isExportContentDialogOpen}
        onSubmit={handleExportContent}
        onClose={() => setIsExportContentDialogOpen(false)}
      />
      <Backdrop
        data-testid="loading-spinner"
        open={isLoading}
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: 'blur(5px)',
        }}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
    </Box>
  );
}

export function isLanguageColumn(id: string) {
  const excludeIds = ['actions', 'id', 'comment', 'lastUpdated'];
  return !excludeIds.includes(id);
}

function toLowerCaseTranslationsKeys(bundles: LicenseTranslationBundle[]): LicenseTranslationBundle[] {
  return bundles.map((bundle: LicenseTranslationBundle) => {
    const translations = Object.fromEntries(
      Object.entries(bundle.translations).map(([key, value]) => [key.toLowerCase(), value]),
    );
    return { ...bundle, translations };
  });
}

export function getColumnDefinitions(
  rowToDeleteSetter: Function,
  saveCellHandler: (oldBundle: LicenseTranslationBundle, property: string, value: string) => void,
  languages: string[],
): MRT_ColumnDef<LicenseTranslationBundle>[] {
  const stage = getHost().stage;
  var isDeleteButtonVisible = true;
  if (stage === 'prod' || stage === 'staging') {
    isDeleteButtonVisible = false;
  }
  const columns: MRT_ColumnDef<LicenseTranslationBundle>[] =
    [
      {
        header: '',
        id: 'actions',
        size: 40,
        Cell: ({ row }) =>
          <Box>
            {
              isDeleteButtonVisible && <Tooltip title='delete entry'>
              <IconButton
                data-testid={'delete-button-' + row.original.id}
                onClick={() => rowToDeleteSetter(row.original.id)}
                // disabled={!isAuth}
                sx={{ padding: 0, height: '20px' }}
              >
                <DeleteIcon style={{ color: '#F32323B5' }}/>
              </IconButton>
            </Tooltip>
            }
          </Box>,
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableEditing: false,
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableResizing: false,
      },
      {
        accessorKey: 'id',
        header: 'ID',
        id: 'id',
        size: 200,
        Header: () =>
          <Box height='24px' sx={{ display: 'flex', gap: '8px' }}>
            <BadgeOutlined/>
            <Typography>ID</Typography>
          </Box>,
        enableEditing: false,
        enableColumnOrdering: false,
      },
      {
        accessorKey: 'comment',
        header: 'Comment',
        id: 'comment',
        size: 200,
        Header: () =>
          <Box height='24px' sx={{ display: 'flex', gap: '8px' }}>
            <CommentOutlined/>
            <Typography>Comment</Typography>
          </Box>,
        Edit: ({ cell, table }) =>
          <CellEditMode
            cell={ cell }
            table={ table }
            onSubmit={ saveCellHandler }
          />,
        enableSorting: false,
      },
      {
        header: 'Last Updated',
        id: 'lastUpdated',
        size: 120,
        accessorKey: 'lastUpdated',
        Header: () =>
          <Box height='24px' sx={{ display: 'flex', gap: '8px' }}>
            <Update/>
          </Box>,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'date-range',
        sortingFn: 'alphanumeric',
        Cell: ({ cell }) => {
          const value = new Date(cell.getValue<Date>())?.toLocaleDateString();
          return value === 'Invalid Date' ? '' : value;
        },
        enableEditing: false,
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableResizing: false,
      },
    ];

  if (!languages || languages.length === 0) {
    return columns;
  }

  languages.forEach((lang: string) => {
    columns.push(
      {
        header: lang.toUpperCase(),
        id: lang,
        size: 250,
        accessorFn: (originalRow) => originalRow.translations[lang] ?? '',
        Header: () =>
          <Box height='24px' sx={{ display: 'flex', gap: '8px' }}>
            <FormatQuote/>
            <Typography>{ lang.toUpperCase() }</Typography>
          </Box>,
        Edit: ({ cell, table }) =>
          <CellEditMode
            cell={ cell }
            table={ table }
            onSubmit={ saveCellHandler }
          />,
        enableEditing: (stage != 'prod' && stage != 'staging'),
        enableSorting: false,
      },
    );
  });

  return columns;
}
