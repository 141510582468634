import { Brand } from '../model/Brand';
import LicenseTranslationBundle from '../model/LicenseTranslationBundle';
import getHost from './GetHost';
import consts from '../constants/url.json';

export default class LicenseTranslationBundleController {

  async getBundlesByOem(oem: Brand, accessToken: string) {
    console.debug('get data for brand: ' + oem);

    const url = new URL(getHost().url + consts.BUNDLE_PATH + '/' + oem.toLowerCase());

    return fetch(url.href, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'x-auth': accessToken,
      },
    });
  }

  async createBundle(data: LicenseTranslationBundle, accessToken: string) {
    console.debug('create id: ' + data.id);

    const url = new URL(getHost().url + consts.BUNDLE_PATH + '/' + data.oem.toLowerCase() + '/' + data.id);

    return fetch(url.href, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth': accessToken,
      },
      body: JSON.stringify(data),
    });
  }

  async updateBundle(data: LicenseTranslationBundle, accessToken: string) {
    console.debug('update id: ' + data.id);

    const url = new URL(getHost().url + consts.BUNDLE_PATH + '/' + data.oem.toLowerCase() + '/' + data.id);

    return fetch(url.href, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-auth': accessToken,
      },
      body: JSON.stringify(data),
    });
  }

  async deleteBundle(id: string, oem: Brand, accessToken: string) {
    console.debug('delete id: ' + id);

    const url = new URL(getHost().url + consts.BUNDLE_PATH + '/' + oem.toLowerCase() + '/' + id);

    return fetch(url.href, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'x-auth': accessToken,
      },
    });
  }
}
