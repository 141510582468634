import React from 'react';
import { MRT_TableInstance } from 'material-react-table';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import SettingsBackupRestoreOutlined from '@mui/icons-material/SettingsBackupRestoreOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';

import LicenseTranslationBundle from '../../../model/LicenseTranslationBundle';

interface Props {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  table: MRT_TableInstance<LicenseTranslationBundle>;
  resetFunc: () => void;
}

export default function OptionsMenu({ anchorEl, setAnchorEl, table, resetFunc }: Props) {

  return (
        <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
        >
            <MenuList>
                <MenuItem
                    onClick={() => table.resetColumnVisibility(true)}
                >
                    <ListItemIcon>
                        <VisibilityOutlined/>
                    </ListItemIcon>
                    <ListItemText>
                        Show All
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={resetFunc}
                >
                    <ListItemIcon>
                        <SettingsBackupRestoreOutlined/>
                    </ListItemIcon>
                    <ListItemText>
                        Reset Formatting
                    </ListItemText>
                </MenuItem>
            </MenuList>
        </Menu>
  );
}