import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { MRT_TableInstance } from 'material-react-table';
import { IconButton, Input, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useSearchParams } from 'react-router-dom';
import LicenseTranslationBundle from '../../model/LicenseTranslationBundle';

interface Props {
  table: MRT_TableInstance<LicenseTranslationBundle>;
}

export default function GlobalSearch(props: Props) {

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentSearch, setCurrentSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    let value = searchParams.get('search') ?? undefined;
    setCurrentSearch(value);
    localStorage.setItem('mrt_globalFilter', JSON.stringify({ value: value }));
  }, [searchParams]);

  function handleSearch(newSearch: string | undefined) {
    if (newSearch) {
      searchParams.set('search', newSearch);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('search');
      setSearchParams(searchParams);
    }
    setCurrentSearch(newSearch);
    props.table.setGlobalFilter(newSearch);
  }

  return (
        <Input
            placeholder='Search'
            value={currentSearch ?? ''}
            onChange={(event) => handleSearch(event.target.value)}
            startAdornment={
                <InputAdornment position='start'>
                    <SearchIcon/>
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position='end'>
                    <IconButton
                        disabled={!currentSearch}
                        onClick={() => handleSearch(undefined)}
                    >
                        <ClearIcon/>
                    </IconButton>
                </InputAdornment>
            }
        />
  );
}