import getHost from './GetHost';

export default class AuthTokenController {

  async getAuthToken(authCode: string) {
    console.debug('get auth token');

    const url = new URL(getHost().url + '/get-auth-token');
    url.searchParams.append('code', authCode);

    return fetch(url.href, {
      method: 'GET',
    });
  }

  async refreshAuthToken(refreshToken: string) {
    console.debug('refresh token');

    const url = new URL(getHost().url + '/refresh-auth-token');
    url.searchParams.append('refresh_token', refreshToken);

    return fetch(url.href);
  }
}