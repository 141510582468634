import React, { useEffect, useRef, useState } from 'react';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_ColumnSizingState,
  MRT_DensityState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_VisibilityState,
} from 'material-react-table';

import { FilterAltOffOutlined, FilterAltOutlined, ViewColumnOutlined } from '@mui/icons-material';

import TopRightToolbar from './TopRightToolbar';
import TopLeftToolbar from './TopLeftToolbar';
import LicenseTranslationBundle from '../../model/LicenseTranslationBundle';

const DEFAULT_COLUMN_FILTERS: MRT_ColumnFiltersState = [];
const DEFAULT_COLUMN_PINNING : MRT_ColumnPinningState = {
  left: ['actions', 'id'],
  right: ['comment', 'lastUpdated'],
};
const DEFAULT_COLUMN_SIZING: MRT_ColumnSizingState = {};
const DEFAULT_COLUMN_VISIBILITY: MRT_VisibilityState = {};
const DEFAULT_DENSITY: MRT_DensityState = 'compact';
const DEFAULT_GLOBAL_FILTER = undefined;
const DEFAULT_PAGINATION: MRT_PaginationState = {
  pageIndex: 0,
  pageSize: 10,
};
const DEFAULT_SHOW_COLUMN_FILTERS_STATE = false;
const DEFAULT_SORTING: MRT_SortingState = [];

interface Props {
  columns: MRT_ColumnDef<LicenseTranslationBundle>[],
  data: LicenseTranslationBundle[],
  setIsAddLanguageDialogOpen: Function
  setIsCreateRowDialogOpen: Function
  setIsExportContentDialogOpen: Function
  columnOrder: MRT_ColumnOrderState,
  setColumnOrder: React.Dispatch<React.SetStateAction<MRT_ColumnOrderState>>
}

export default function PersistentMaterialReactTable(
  {
    columns,
    data,
    setIsAddLanguageDialogOpen,
    setIsCreateRowDialogOpen,
    setIsExportContentDialogOpen,
    columnOrder,
    setColumnOrder,
  }: Props) {
  const isFirstRender = useRef<boolean>(true);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(DEFAULT_COLUMN_FILTERS);
  const [columnPinning, setColumnPinning] = useState<MRT_ColumnPinningState>(DEFAULT_COLUMN_PINNING);
  const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>(DEFAULT_COLUMN_SIZING);
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(DEFAULT_COLUMN_VISIBILITY);
  const [density, setDensity] = useState<MRT_DensityState>(DEFAULT_DENSITY);
  const [globalFilter, setGlobalFilter] = useState<string | undefined>(DEFAULT_GLOBAL_FILTER);
  const [pagination, setPagination] = useState<MRT_PaginationState>(DEFAULT_PAGINATION);
  const [showColumnFilters, setShowColumnFilters] = useState<boolean>(DEFAULT_SHOW_COLUMN_FILTERS_STATE);
  const [sorting, setSorting] = useState<MRT_SortingState>(DEFAULT_SORTING);

  useEffect(() => {
    const storedColumnFilters = localStorage.getItem('mrt_columnFilters');
    const storedColumnOrder = localStorage.getItem('mrt_columnOrder');
    const storedColumnPinning = localStorage.getItem('mrt_columnPinning');
    const storedColumnSizing = localStorage.getItem('mrt_columnSizing');
    const storedColumnVisibility = localStorage.getItem('mrt_columnVisibility');
    const storedDensity = localStorage.getItem('mrt_density');
    const storedGlobalFilter = localStorage.getItem('mrt_globalFilter');
    const storedPagination = localStorage.getItem('mrt_pagination');
    const storedShowColumnFilters = localStorage.getItem('mrt_showColumnFilters');
    const storedSorting = localStorage.getItem('mrt_sorting');

    if (storedColumnFilters) setColumnFilters(JSON.parse(storedColumnFilters));
    if (storedColumnOrder && storedColumnOrder.length > 0) setColumnOrder(JSON.parse(storedColumnOrder));
    if (storedColumnPinning) setColumnPinning(JSON.parse(storedColumnPinning));
    if (storedColumnSizing) setColumnSizing(JSON.parse(storedColumnSizing));
    if (storedColumnVisibility) setColumnVisibility(JSON.parse(storedColumnVisibility));
    if (storedDensity) setDensity(JSON.parse(storedDensity));
    if (storedGlobalFilter) setGlobalFilter(JSON.parse(storedGlobalFilter).value);
    if (storedPagination) setPagination(JSON.parse(storedPagination));
    if (storedShowColumnFilters) setShowColumnFilters(JSON.parse(storedShowColumnFilters));
    if (storedSorting) setSorting(JSON.parse(storedSorting));
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    if (isFirstRender.current) return;
    localStorage.setItem('mrt_columnFilters', JSON.stringify(columnFilters));
  }, [columnFilters]);

  useEffect(() => {
    if (isFirstRender.current) return;
    if (!columnOrder || columnOrder.length === 0) return;
    localStorage.setItem('mrt_columnOrder', JSON.stringify(columnOrder));
  }, [columnOrder]);

  useEffect(() => {
    if (isFirstRender.current) return;
    localStorage.setItem('mrt_columnPinning', JSON.stringify(columnPinning));
  }, [columnPinning]);

  useEffect(() => {
    if (isFirstRender.current) return;
    localStorage.setItem('mrt_columnSizing', JSON.stringify(columnSizing));
  }, [columnSizing]);

  useEffect(() => {
    if (isFirstRender.current) return;
    localStorage.setItem('mrt_columnVisibility', JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  useEffect(() => {
    if (isFirstRender.current) return;
    localStorage.setItem('mrt_density', JSON.stringify(density));
  }, [density]);

  useEffect(() => {
    if (isFirstRender.current) return;
    localStorage.setItem('mrt_globalFilter', JSON.stringify({ value: globalFilter }));
  }, [globalFilter]);

  useEffect(() => {
    if (isFirstRender.current) return;
    localStorage.setItem('mrt_pagination', JSON.stringify(pagination));
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (isFirstRender.current) return;
    localStorage.setItem('mrt_showColumnFilters', JSON.stringify(showColumnFilters));
  }, [showColumnFilters]);

  useEffect(() => {
    if (isFirstRender.current) return;
    localStorage.setItem('mrt_sorting', JSON.stringify(sorting));
  }, [sorting]);

  function resetState() {
    let defaultColumnOrder = columns.map(col => col.id as string);

    setColumnFilters(DEFAULT_COLUMN_FILTERS);
    setColumnOrder(defaultColumnOrder);
    setColumnPinning(DEFAULT_COLUMN_PINNING);
    setColumnSizing(DEFAULT_COLUMN_SIZING);
    setColumnVisibility(DEFAULT_COLUMN_VISIBILITY);
    setDensity(DEFAULT_DENSITY);
    setGlobalFilter(DEFAULT_GLOBAL_FILTER);
    setPagination(DEFAULT_PAGINATION);
    setShowColumnFilters(DEFAULT_SHOW_COLUMN_FILTERS_STATE);
    setSorting(DEFAULT_SORTING);
  }

  return (
        <MaterialReactTable
            columns={columns}
            data={data}

            state={{
              columnFilters,
              columnOrder,
              columnPinning,
              columnSizing,
              columnVisibility,
              density,
              globalFilter,
              pagination,
              showColumnFilters,
              sorting,
            }}

            onColumnFiltersChange={setColumnFilters}
            enableColumnOrdering
            onColumnOrderChange={setColumnOrder}
            enablePinning
            onColumnPinningChange={setColumnPinning}
            enableColumnResizing
            columnResizeMode='onChange'
            onColumnSizingChange={setColumnSizing}
            onColumnVisibilityChange={setColumnVisibility}
            onDensityChange={setDensity}
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            onShowColumnFiltersChange={setShowColumnFilters}
            onSortingChange={setSorting}

            enableEditing
            editDisplayMode="cell"
            columnFilterDisplayMode='popover'

            icons={{
              FilterListIcon: defineFilterListIcon,
              FilterListOffIcon: defineFilterListOffIcon,
              ViewColumnIcon: defineViewColumnIcon,
            }}

            renderTopToolbarCustomActions={() => (
                <TopLeftToolbar
                    onClickAddColumn={() => setIsAddLanguageDialogOpen(true)}
                    onClickAddRow={() => setIsCreateRowDialogOpen(true)}
                    onClickExportContent={() => setIsExportContentDialogOpen(true)}
                />
            )}

            // @ts-ignore
            renderToolbarInternalActions={({ table }) => (
                <TopRightToolbar
                    table={table}
                    resetFunc={resetState}
                />
            )}
        />
  );
}

function defineFilterListIcon(props: any) {
  return <FilterAltOutlined {...props} />;
}

function defineFilterListOffIcon(props: any) {
  return <FilterAltOffOutlined {...props} />;
}

function defineViewColumnIcon(props: any) {
  // eslint-disable-next-line react/display-name
  return () => <ViewColumnOutlined {...props} />;
}
