import React, { useEffect, useState } from 'react';
import { Box, Select, SelectChangeEvent } from '@mui/material';
import { green, indigo, red, yellow } from '@mui/material/colors';

import getHost from '../../Controller/GetHost';
import MenuItem from '@mui/material/MenuItem';
import { createBrowserHistory } from 'history';

export default function StageIndikator() {

  const browserHistory = createBrowserHistory();
  const [selectedstage, setSelectedstage] = useState<string>('');
  const [stages, setStages] = useState<any[]>([
    { name: 'dev', color: green[400], nameToShow: 'demo' },
    { name: 'staging', color: yellow[800], nameToShow: 'approval' },
    { name: 'prod', color: red[800], nameToShow: 'live' },
  ]);

  useEffect(() => {
    const stage = getHost().stage;
    setSelectedstage(stage);
    const newStages = stages;

    if (stage === 'local') {
      const isLocalStageExist = stages.some((stageName) => stageName.name === 'local');
      const isSandboxStageExist = stages.some((stageName) => stageName.name === 'sandbox');
      if (!isSandboxStageExist) {
        newStages.unshift({ name: 'sandbox', color: indigo[400], nameToShow: 'sandbox' });
      }
      if (!isLocalStageExist) {
        newStages.unshift({ name: 'local', color: indigo[400], nameToShow: 'local' });
      }
    }

    if (stage === 'sandbox') {
      const isLocalStageExist = stages.some((stageName) => stageName.name === 'sandbox');
      if (!isLocalStageExist)
        newStages.unshift({ name: 'sandbox', color: indigo[400], nameToShow: 'sandbox' });
    }

    setStages(newStages);

  }, []);

  function handleChangeStage(event: SelectChangeEvent) {
    const currentPath = window.location.pathname;
    const currentSearchParam = window.location.search;
    browserHistory.push('https://portal.' + event.target.value + '.lscree.cariad.digital' + currentPath + currentSearchParam);
  }

  return (
    <Select
      data-testid="stage-selector"
      id="stage-selector"
      value={ selectedstage }
      onChange={ handleChangeStage }
      autoWidth
      sx={ {
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
      } }
    >
      { stages.map((stage) => (
        <MenuItem value={ stage.name } key={ stage.name }>
          { stage.name === selectedstage ?
            <Box
              data-testid="stage-indicator"
              sx={ {
                width: 80,
                height: 30,
                borderRadius: 1,
                bgcolor: 'white',
                boxShadow: 'inset 3px 3px 3px #ccc',
                color: stage.color,
                fontWeight: 'bold',
                fontSize: '0.75rem',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              } }
            >
              { stage.nameToShow }
            </Box>
            :
            <Box
              data-testid={'stage-indicator-' + stage.name}
              sx={ {
                width: 80,
                height: 30,
                borderRadius: 1,
                bgcolor: 'white',
                color: stage.color,
                fontWeight: 'bold',
                fontSize: '0.75rem',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              } }
            >
              { stage.nameToShow  }
            </Box>
          }
        </MenuItem>
      )) }
    </Select>
  );
}
