import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { AppBar, Grid, IconButton, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TableRowsIcon from '@mui/icons-material/TableRows';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import { indigo } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';

import AccountMenu from './AccountMenu';
import URL from '../../constants/url.json';
import StageIndikator from './StageIndikator';

export const HEADER_FONT_SIZE = '1.25rem';
export const HEADER_LOGO_SIZE = '64px';

export const iconButtonStyle = {
  padding: 0,
  color: 'white',
  fontSize: '48px',
  '&.Mui-disabled': {
    color: indigo[300],
    fontSize: '32px',
    padding: '8px',
  },
};

export default function Header() {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onDashboardRoute = Boolean(useMatch(URL.DASHBOARD_PATH));
  const onAboutRoute = Boolean(useMatch(URL.ABOUT_PATH));
  const onLogoutRoute = Boolean(useMatch(URL.LOGOUT_SUCCESS_PATH));
  const onContentPackagesRoute = Boolean(useMatch(URL.CONTENT_PACKAGES_PATH));

  return (
    <AppBar position="static" sx={{ height: '64px', marginY: '8px', borderRadius: '8px' }}>
      <Grid container
            className='header-grid'
            direction="row"
            spacing={0}
            justifyContent="space-between"
            alignItems="center"
            p={0}
            height='100%'
      >
        <Grid item xs={4} sx={{
          padding: 0,
          height: '100%',
          display: 'flex',
        }}
        >
          <Grid container
                direction="row"
                spacing={1}
                pl='8px'
                justifyContent="flex-start"
                alignItems="center"
          >
            <Grid item>
              <Tooltip title="Dashboard">
                                <span>
                                    <IconButton
                                      onClick={() => navigate(URL.DASHBOARD_PATH + '?' + searchParams)}
                                      disabled={onDashboardRoute || onLogoutRoute}
                                      sx={iconButtonStyle}
                                    >
                                        <TableRowsIcon fontSize="inherit"/>
                                    </IconButton>
                                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Content packages">
                                <span>
                                    <IconButton data-testid="content-packages-icon-button"
                                                onClick={() => navigate(URL.CONTENT_PACKAGES_PATH + '?' + searchParams)}
                                                disabled={onContentPackagesRoute || onLogoutRoute}
                                                sx={iconButtonStyle}
                                    >
                                        <FolderCopyIcon fontSize="inherit"/>
                                    </IconButton>
                                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: HEADER_FONT_SIZE }}>
                LicenseScreen
              </Typography>
            </Grid>
            <Grid item>
              <StageIndikator/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sx={{
          padding: 0,
          height: '100%',
          display: 'flex',
        }}
        >
          <Grid container
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
          >
            <Grid item>
              <img src='/wordmark_white.svg' alt='Valtech Mobility' height={HEADER_LOGO_SIZE}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sx={{
          padding: 0,
          height: '100%',
          display: 'flex',
        }}
        >
          <Grid container
                direction="row"
                spacing={1}
                pr='8px'
                justifyContent="flex-end"
                alignItems="center"
          >
            <Grid item>
              <Tooltip title="About">
                                <span>
                                    <IconButton
                                      onClick={() => navigate(URL.ABOUT_PATH)}
                                      disabled={onAboutRoute || onLogoutRoute}
                                      sx={iconButtonStyle}
                                    >
                                        <InfoIcon fontSize="inherit"/>
                                    </IconButton>
                                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <AccountMenu disabled={onLogoutRoute}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
}