import { SvgIcon } from '@mui/material';
import React from 'react';

export default function PushPinOffOutlined(props: { fill?: string }) {
  return <SvgIcon
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          height: '20px',
          width: '20px',
        }}
    >
        <svg
            viewBox="0 0 20 20"
            fill= {props.fill ?? 'currentColor'}
        >
            <path
                d="m 5.707031,0 c -0.549997,0 -1,0.4500031 -1,1 0,0.5499969 0.450003,1 1,1 h 1 V 3.171875 L 15.535156,12 h 2.171875 v -2 c -1.659988,0 -3,-1.340012 -3,-3 V 2 h 1 c 0.549994,0 1,-0.4500031 1,-1 0,-0.5499969 -0.450006,-1 -1,-1 z M 2.1210935,1.4140625 0.707031,2.828125 l 5.625,5.625 C 5.82114,9.376054 4.839115,10 3.707031,10 v 2 h 5.970703 v 7 l 1,1 1,-1 V 13.798828 L 17.878906,20 19.292969,18.585938 12.707031,12 l -2,-2 L 8.621094,7.9140625 6.707031,6 Z"
            />
        </svg>
    </SvgIcon>;
}