import getHost from './GetHost';
import consts from '../constants/url.json';

export default class LanguageColumnController {

  async getAllLanguages(accessToken: string) {
    console.debug('get all languages');

    const url = new URL(getHost().url + consts.LANG_PATH);

    return fetch(url.href, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'x-auth': accessToken,
      },
    });
  }

  async appendLanguage(lang: string, accessToken: string) {
    console.debug('append language: ' + lang);

    const url = new URL(getHost().url + consts.LANG_PATH + '/' + lang);

    return fetch(url.href, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth': accessToken,
      },
    });
  }
}