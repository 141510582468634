import React from 'react';
import { MRT_Cell, MRT_TableInstance } from 'material-react-table';
import { TextField } from '@mui/material';
import LicenseTranslationBundle from '../../model/LicenseTranslationBundle';

interface Props {
  cell: MRT_Cell<LicenseTranslationBundle>,
  table: MRT_TableInstance<LicenseTranslationBundle>,
  onSubmit: (oldLicenseString: LicenseTranslationBundle, property: string, value: string) => void,
}

export default function cellEditMode({ cell, table, onSubmit }: Props) {
  return (
        <TextField
            sx={{ width: '100%' }}
            autoFocus
            variant='standard'
            multiline={true}
            defaultValue={cell.getValue()}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                onSubmit(cell.row.original, cell.column.id, (event.target as HTMLTextAreaElement).value);
                table.setEditingCell(null);
              } else if (event.key === 'Escape') {
                table.setEditingCell(null);
              }
            }}
            onBlur={() => table.setEditingCell(null)}
        />
  );
}