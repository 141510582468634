import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { PushPin, PushPinOutlined } from '@mui/icons-material';
import PushPinOffOutlined from './PushPinOffOutlined';
import React from 'react';
import { MRT_Column } from 'material-react-table';
import PushPinOff from './PushPinOff';
import LicenseTranslationBundle from '../../../model/LicenseTranslationBundle';

export default function PinningMenuItem(props: {
  column: MRT_Column<LicenseTranslationBundle>
}) {
  return <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
          gap: '8px',
          py: '4px',
        }}
    >
        <ToggleButtonGroup
            value={props.column.getIsPinned()}
            exclusive
            onChange={(event, value) => props.column.pin(value)}
            aria-label="text alignment"
        >
            <ToggleButton value="left" aria-label="left pinned">
                {props.column.getIsPinned() === 'left'
                  ?
                    <PushPin sx={{ transform: 'rotate(90deg)' }}/>
                  :
                    <PushPinOutlined sx={{ transform: 'rotate(90deg)' }}/>
                }
            </ToggleButton>
            <ToggleButton value={false} aria-label="unpinned" sx={{ width: '48px' }}>
                {!props.column.getIsPinned()
                  ?
                    <PushPinOff fill='red'/>
                  :
                    <PushPinOffOutlined fill='red'/>
                }
            </ToggleButton>
            <ToggleButton value="right" aria-label="right pinned">
                {props.column.getIsPinned() === 'right'
                  ?
                    <PushPin sx={{ transform: 'rotate(-90deg)' }}/>
                  :
                    <PushPinOutlined sx={{ transform: 'rotate(-90deg)' }}/>
                }
            </ToggleButton>
        </ToggleButtonGroup>
        <Typography>
            {props.column.columnDef.header}
        </Typography>
    </Box>;
}