import { MRT_TableInstance } from 'material-react-table';
import React, { type MouseEvent, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import { PushPinOutlined } from '@mui/icons-material';
import PinningMenu from './PinningMenu';
import LicenseTranslationBundle from '../../../model/LicenseTranslationBundle';

export default function PinningMenuButton(props: { table: MRT_TableInstance<LicenseTranslationBundle> }) {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function handleClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  return (
        <React.Fragment>
            <Tooltip arrow title='Manage pinning'>
                <IconButton
                    onClick={handleClick}
                >
                    <PushPinOutlined />
                </IconButton>
            </Tooltip>
            {anchorEl && (
                <PinningMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    table={props.table}
                />
            )}
        </React.Fragment>
  );
}