import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  TextField,
  Typography,
} from '@mui/material';
import { PostAddOutlined } from '@mui/icons-material';

interface AddLanguageProps {
  onClose: () => void;
  onSubmit: (lang: string) => void;
  open: boolean;
}

export default function AddLanguageDialog({ onClose, onSubmit, open } : AddLanguageProps) {
  const [newLanguage, setNewLanguage] = useState({ name: '' });

  function handleConfirmNewLanguage() {
    const userInput = newLanguage.name.toLowerCase();
    onSubmit(userInput);
    onClose();
    setNewLanguage({ name: '' });
  }

  function getHelperText() {
    if (!newLanguage.name) {
      return 'Must not be empty.';
    }
    return '';
  }

  return (
        <Dialog open={open} onClose={onClose} data-testid='add-language-dialog'>
            <AppBar
                position='static'
            >
                <DialogTitle
                    sx={{
                      alignItems:'center',
                      display:'flex',
                      gap: '24px',
                    }}
                >
                    <Icon>
                        <PostAddOutlined sx={{ transform: 'rotate(-90deg) scaleY(-1)', marginRight: '20px' }}/>
                    </Icon>
                    <Typography sx={{ fontSize: 'large' }}>
                        Add Language
                    </Typography>
                </DialogTitle>
            </AppBar>
            <DialogContent>
                <Box height={80} pt={1}>
                    <TextField
                        autoComplete="false"
                        error={!!getHelperText()}
                        label="Language Abbreviation"
                        name="name"
                        helperText={getHelperText()}
                        onChange={(e) =>
                          setNewLanguage({ ...newLanguage, name: e.target.value })
                        }
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem', justifyContent: 'space-between' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleConfirmNewLanguage} variant="contained" disabled={!!getHelperText()}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
  );
}