import React, { useContext, useEffect, useState } from 'react';
import { Brand } from '../../model/Brand';
import { FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

export default function BrandSelect() {

  const authContext = useContext(AuthContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBrand, setSelectedBrand] = useState<Brand>();

  useEffect(() => {
    setSelectedBrand(searchParams.get('brand') as Brand);
  }, [searchParams]);

  function handleSelection(e: SelectChangeEvent<Brand>) {
    const brand = e.target.value as Brand;
    searchParams.set('brand', brand);
    setSelectedBrand(brand);
    setSearchParams(searchParams);
  }

  return (
        <FormControl
            sx={{
              minWidth: 170,
              background: 'none',
              borderRadius: '4px',
            }}
            color="primary"
            size="small"
        >
            <InputLabel>
                Selected Brand
            </InputLabel>
            <Select
                value={selectedBrand ?? ''}
                label="Selected Brand"
                onChange={handleSelection}
                data-testid='selected-brand-dropdown'
            >
                <MenuItem
                    value="audi"
                    disabled={!authContext.isAuthorized('audi')}
                >
                    Audi
                </MenuItem>
                <MenuItem
                    value="bentley"
                    disabled={!authContext.isAuthorized('bentley')}
                >
                    Bentley
                </MenuItem>
                <MenuItem
                    value="lamborghini"
                    disabled={!authContext.isAuthorized('lamborghini')}
                >
                    Lamborghini
                </MenuItem>
            </Select>
        </FormControl>
  );
}
