import { LogoutRounded } from '@mui/icons-material';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

export default function LogoutSuccess() {

  const authContext = useContext(AuthContext);

  function handleLogin() {
    authContext.login();
  }

  return (
        <Box display='flex' justifyContent='center' p='50px'>
            <Paper
                elevation={3}
                sx={{
                  width: '400px',
                  height: '200px',
                }}
            >
                <Grid
                    container
                    height='100%'
                    direction='column'
                    spacing={2}
                    gap={1}
                    justifyContent='center'
                    alignItems='center'
                >
                    <Grid item>
                        <LogoutRounded />
                    </Grid>
                    <Grid item>
                        <Typography>
                            You have successfully logged out.
                        </Typography>
                    </Grid>
                  <Grid>
                      {<Button onClick={handleLogin} sx={ {
                        borderRadius: 1,
                        boxShadow: '3px 3px 3px #ccc',
                        fontWeight: 'bold',
                        fontSize: '0.75rem',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '10px',
                      } }>
                          Click here to Login
                      </Button>
                      }
                    </Grid>
                </Grid>
            </Paper>
        </Box>
  );
}